import endsWith from "lodash/endsWith";
var ZERO_CENTS = ".00";
var CURRENCIES = {
    AUD: {
        formatter: new Intl.NumberFormat("en-AU", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }),
        prefix: "A$",
    },
    CNY: {
        formatter: new Intl.NumberFormat("zh", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }),
        prefix: "¥",
    },
    SGD: {
        formatter: new Intl.NumberFormat("en-SG", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }),
        prefix: "S$",
    },
    IDR: {
        formatter: new Intl.NumberFormat("id-ID", {
            style: "decimal",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }),
        prefix: "Rp ",
    },
};
export function moneyFormat(_a) {
    var currency = _a.currency, amount = _a.amount;
    if (!(currency in CURRENCIES)) {
        throw new Error("Currency ".concat(currency, " not recognised"));
    }
    var _b = CURRENCIES[currency], prefix = _b.prefix, formatter = _b.formatter;
    var raw = prefix + formatter.format(Math.abs(parseFloat(amount)));
    if (parseFloat(amount) < 0) {
        raw = "-" + raw;
    }
    if (endsWith(raw, ZERO_CENTS)) {
        raw = raw.substr(0, raw.length - ZERO_CENTS.length);
    }
    return raw;
}
export function moneyFormatAud(amount) {
    return moneyFormat({ amount: amount, currency: "AUD" });
}
export var nl2br = function (content) {
    return content.replace(/\n/g, "<br/>");
};
export var obscuredCard = function (_a) {
    var last4 = _a.last4, expMonth = _a.expMonth, expYear = _a.expYear;
    return "**** **** **** ".concat(last4, " (").concat(expMonth, "/").concat(expYear, ")");
};
