var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import uniqueId from "lodash/uniqueId";
import findIndex from "lodash/findIndex";
import Cookie from "js-cookie";
var languageCookieExpiryInDays = 365 * 3;
var authUserCookieExpiryInDays = 31 * 3;
export var createStore = function (_a) {
    var strict = _a.strict, persist = _a.persist, initState = _a.initState;
    return new Store({
        strict: strict,
        plugins: persist
            ? [
                createPersistedState({
                    key: "studentguru",
                    paths: ["cartId"],
                }),
            ]
            : [],
        state: __assign({ messages: [] }, initState),
        mutations: {
            removeNotificationMessage: function (state, removeId) {
                var index = findIndex(state.messages, function (_a) {
                    var id = _a.id;
                    return id === removeId;
                });
                if (index >= 0) {
                    state.messages.splice(index, 1);
                }
            },
            addErrorMessage: function (state, props) {
                state.messages.push(__assign({ type: "error" }, props));
            },
            addSuccessMessage: function (state, props) {
                state.messages.push(__assign({ type: "success" }, props));
            },
            storeCart: function (state, _a) {
                var cartId = _a.cartId;
                state.cartId = cartId;
            },
            clearCart: function (state) {
                state.cartId = undefined;
            },
            setLanguage: function (_, language) {
                Cookie.set("language", language.value, {
                    expires: languageCookieExpiryInDays,
                });
            },
            storeAuthUser: function (state, authUser) {
                state.authUser = authUser;
                Cookie.set("authUser", JSON.stringify(authUser), {
                    expires: authUserCookieExpiryInDays,
                });
            },
            updateAuthToken: function (state, newToken) {
                if (!state.authUser) {
                    throw new Error("No auth user");
                }
                var newAuthUser = __assign(__assign({}, state.authUser), { token: newToken });
                state.authUser = newAuthUser;
                Cookie.set("AUTH_USER", JSON.stringify(newAuthUser), {
                    expires: authUserCookieExpiryInDays,
                });
            },
            removeAuthUser: function (state) {
                state.authUser = undefined;
                Cookie.remove("authUser");
            },
        },
        actions: {
            displaySuccessMessage: function (context, content) {
                var id = uniqueId();
                context.commit("addSuccessMessage", {
                    id: id,
                    content: content.toString(),
                });
                setTimeout(function () { return context.commit("removeNotificationMessage", id); }, 10000);
            },
            displayErrorMessage: function (context, error) {
                var id = uniqueId();
                var content = typeof error === "object" ? error.message : error;
                context.commit("addErrorMessage", { id: id, content: content });
                setTimeout(function () { return context.commit("removeNotificationMessage", id); }, 10000);
            },
        },
    });
};
