var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApolloClient } from "apollo-client";
import { BatchHttpLink } from "apollo-link-batch-http";
// import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { isServerError } from "./errors";
export default function (_a) {
    var store = _a.store, uri = _a.uri, ssrMode = _a.ssrMode;
    var setAuthorizationLink = setContext(function () {
        var authUser = store.state.authUser;
        if (!authUser) {
            return;
        }
        return {
            headers: {
                authorization: "JWT ".concat(authUser.token),
            },
        };
    });
    // TODO
    /*const refreshLink = new TokenRefreshLink({
      isTokenValidOrUndefined: () => !isTokenExpired() || typeof getAccessToken() !== 'string',
      fetchAccessToken: () => {
        return fetch(getEndpoint('getAccessTokenPath'), {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'refresh-token': getRefreshToken()
          }
        });
      },
      handleFetch: accessToken => {
        const accessTokenDecrypted = jwtDecode(accessToken);
        setAccessToken(accessToken);
        setExpiresIn(parseExp(accessTokenDecrypted.exp).toString());
      },
      handleResponse: (operation, accessTokenField) => response => {
        // here you can parse response, handle errors, prepare returned token to
        // further operations
  
        // returned object should be like this:
        // {
        //    access_token: 'token string here'
        // }
      },
      handleError() {
        store.commit("removeAuthUser");
      }
    })*/
    // Send x-gclid header if one is present in the client and it's a mutation
    var setGclid = setContext(function (operation, _a) {
        var headers = _a.headers;
        if (!operation.query.definitions.some(function (def) {
            return def.kind === "OperationDefinition" && def.operation === "mutation";
        })) {
            return;
        }
        if (!store.state.gclid) {
            return { headers: headers };
        }
        return {
            headers: __assign({ "x-gclid": store.state.gclid }, headers),
        };
    });
    var resetToken = onError(function (_a) {
        var networkError = _a.networkError;
        if (networkError &&
            isServerError(networkError) &&
            networkError.statusCode === 401) {
            // remove cached token on 401 from the server
            store.commit("removeAuthUser");
        }
    });
    var batchLink = new BatchHttpLink({ uri: uri, fetch: fetch });
    return new ApolloClient({
        link: setAuthorizationLink
            .concat(setGclid)
            .concat(resetToken)
            .concat(batchLink),
        cache: new InMemoryCache(),
        ssrMode: ssrMode,
        ssrForceFetchDelay: ssrMode ? undefined : 1000,
    });
}
